












import { TargetFaceControl } from '../TargetFaceControl'

import Vue from 'vue'
import { Component, VModel } from 'vue-property-decorator'
import { plainToInstance } from 'class-transformer'

@Component({})
export default class InitialiseConditions extends Vue {
  @VModel() conditions!: null | TargetFaceControl

  initialiseConditions() {
    this.conditions = plainToInstance(TargetFaceControl, {})
  }
}

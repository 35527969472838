

















import ConditionIcons from '@/includes/logic/FaceControl/conditions/ConditionIcons'
import { FaceControlConditionTypes } from '@/includes/types/FaceControl.types'

import { Item, ListConfig } from 'piramis-base-components/src/components/Pi/components/DefaultList/logic/types'
import DefaultList from 'piramis-base-components/src/components/Pi/components/DefaultList/DefaultList.vue'
import AddEntityCard from "piramis-base-components/src/components/AddEntityCard.vue";
import { isEnumValue } from 'piramis-base-components/src/shared/types/utils'

import Vue from 'vue'
import { snakeCase } from 'lodash'
import { Guid } from 'guid-typescript'
import { Component, Emit, Prop } from 'vue-property-decorator'

@Component({
  components: {
    AddEntityCard,
    DefaultList
  }
})
export default class AddFaceControlCondition extends Vue {
  @Prop() group!: string

  @Emit()
  addConditionGroup(conditionType: FaceControlConditionTypes): FaceControlConditionTypes {
    return conditionType
  }

  modal = false

  getGroupItemStruct(type: FaceControlConditionTypes): Item {
    const scType = snakeCase(type)

    const helpMessageKey = `face_control_condition_${ scType }_help_message`

    return {
      key: type,
      title: this.$t(`face_control_condition_${ scType }_title`).toString(),
      helpMessage: this.$te(helpMessageKey) ? this.$t(helpMessageKey).toString() : undefined,
      icon: ConditionIcons[type]
    }
  }

  get conditionsGroupByName(): Array<Item> {
    return [
      this.getGroupItemStruct(FaceControlConditionTypes.Name_contains),
      this.getGroupItemStruct(FaceControlConditionTypes.Name_english),
      this.getGroupItemStruct(FaceControlConditionTypes.Name_russian),
      this.getGroupItemStruct(FaceControlConditionTypes.Name_rtl),
      this.getGroupItemStruct(FaceControlConditionTypes.Name_han),
      this.getGroupItemStruct(FaceControlConditionTypes.Name_regex),
      this.getGroupItemStruct(FaceControlConditionTypes.Real_name),
    ]
  }

  get conditionsGroupByDescription(): Array<Item> {
    return [
      this.getGroupItemStruct(FaceControlConditionTypes.Description_english),
      this.getGroupItemStruct(FaceControlConditionTypes.Description_russian),
      this.getGroupItemStruct(FaceControlConditionTypes.Description_rtl),
      this.getGroupItemStruct(FaceControlConditionTypes.Description_han),
      this.getGroupItemStruct(FaceControlConditionTypes.Description_regex),
      this.getGroupItemStruct(FaceControlConditionTypes.References),
    ]
  }

  get conditionsGroupByPersonalChannel(): Array<Item> {
    return [
      this.getGroupItemStruct(FaceControlConditionTypes.PersonalChannel),
      this.getGroupItemStruct(FaceControlConditionTypes.PersonalChannelTitle),
      this.getGroupItemStruct(FaceControlConditionTypes.PersonalChannelDescription),
      this.getGroupItemStruct(FaceControlConditionTypes.PersonalChannelMembers),
    ]
  }

  get conditionsGroupByOther(): Array<Item> {
    return [
      this.getGroupItemStruct(FaceControlConditionTypes.Username),
      this.getGroupItemStruct(FaceControlConditionTypes.Avatar),
      this.getGroupItemStruct(FaceControlConditionTypes.Premium),
      this.getGroupItemStruct(FaceControlConditionTypes.Business),
    ]
  }

  get conditions(): ListConfig {
    return {
      data: [
        {
          group: {
            guid: Guid.create().toString(),
            titleKey: 'face_control_condition_group_name',
            groupItems: this.conditionsGroupByName
          }
        },
        {
          group: {
            guid: Guid.create().toString(),
            titleKey: 'face_control_condition_group_description',
            groupItems: this.conditionsGroupByDescription
          }
        },
        {
          group: {
            guid: Guid.create().toString(),
            titleKey: 'face_control_condition_group_personal_channel',
            groupItems: this.conditionsGroupByPersonalChannel
          }

        },
        {
          group: {
            guid: Guid.create().toString(),
            titleKey: 'face_control_condition_group_other',
            groupItems: this.conditionsGroupByOther
          }

        }
      ],
      selectFn: (item: Item) => {
        if (isEnumValue(FaceControlConditionTypes, item.key)) {
          this.addConditionGroup(item.key)
          this.modal = false
        }
      }
    }
  }
}

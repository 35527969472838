import { FaceControlCondition } from "@/includes/logic/FaceControl/conditions/FaceControlCondition";
import { FaceControlConditionTypes } from "@/includes/types/FaceControl.types";

export class RegexCondition extends FaceControlCondition {

    pattern = ''

    invert = false

    view = () => import('./RegexConditionView.vue')

    protected constructor(type: FaceControlConditionTypes) {
        super(type)
    }

    validate() {
        return this.pattern !== '';
    }
}
import ConditionsFabric from './ConditionsFabric'
import { FaceControlCondition } from "./conditions/FaceControlCondition";
import { FaceControlConditionTypes, FaceControlMode } from '@/includes/types/FaceControl.types'

import { IterableItemFactory, IterableListItem } from 'piramis-base-components/src/shared/utils/IterableItemFactory'

import { instanceToPlain, plainToInstance, Transform } from 'class-transformer'

export class TargetFaceControl {
    mode: FaceControlMode = FaceControlMode.Accept

    active = true

    @Transform(({ value }) => {
        const iterableItemFactory = new IterableItemFactory()

        return value.map((r: Array<any>) => {

            const conditions = r.map((b) => {
                const ConditionConstructor = ConditionsFabric.createCondition(b.type)

                if (ConditionConstructor) {
                    return iterableItemFactory.create(plainToInstance(ConditionConstructor, b))
                }
            })

            return iterableItemFactory.create(conditions)
        })
    }, { toClassOnly: true })
    @Transform(({ value }: { value: Array<IterableListItem<Array<IterableListItem<FaceControlCondition>>>> }) => {
        return value.map(g => {
            return g.value.map(c => c.value)
        })
    }, { toPlainOnly: true })
    conditions: Array<IterableListItem<Array<IterableListItem<FaceControlCondition>>>> = []

    findGroupByGuid(guid: string) {
        return this.conditions.find(g => g.guid === guid)
    }

    createConditionGroup() {
        const iterableItemFactory = new IterableItemFactory()

        this.conditions.push(iterableItemFactory.create<Array<IterableListItem<FaceControlCondition>>>([]))
    }

    removeConditionGroup(guid: string) {
        this.conditions = this.conditions.filter(g => g.guid !== guid)
    }

    addCondition(groupGuid: string, conditionType:FaceControlConditionTypes) {
        const iterableItemFactory = new IterableItemFactory()

        const ConditionConstructor = ConditionsFabric.createCondition(conditionType)

        const group = this.findGroupByGuid(groupGuid)

        if (group) {
            group.value.push(iterableItemFactory.create(new ConditionConstructor()))
        }
    }

    removeCondition(groupGuid: string, conditionGuid:string) {
        const group = this.findGroupByGuid(groupGuid)

        if (group) {
            group.value = group.value.filter(c => c.guid !== conditionGuid)
        }
    }

    validateConditions() {
        return new Promise<true>((resolve, reject) => {
            this.conditions.forEach(group => {
                if (!group.value.length) {
                    reject(group)
                } else {
                    group.value.forEach(condition => {
                        if (!condition.value.validate()) {
                            reject(condition)
                        }
                    })
                }

            })

            resolve(true)
        })
    }

    toPlain() {
        return instanceToPlain(this)
    }
}

import { FaceControlConditionTypes } from "@/includes/types/FaceControl.types";

import { VueComponent } from "piramis-base-components/src/shared/types/VueComponent";

import { Exclude } from "class-transformer";
import { snakeCase } from "lodash";

export abstract class FaceControlCondition {
    type: FaceControlConditionTypes;

    @Exclude()
    title: string;

    @Exclude()
    abstract view: VueComponent

    protected constructor(type: FaceControlConditionTypes) {
        this.type = type
        this.title = `face_control_condition_${ snakeCase(type) }_title`
    }

    abstract validate()
}
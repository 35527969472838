export const enum FaceControlMode {
    Accept = 'Accept',
    Reject = 'Reject'
}

export enum FaceControlConditionTypes {
    Avatar = 'avatar',
    References = 'references',
    Premium = 'premium',
    Business = 'business',

    PersonalChannel = 'personal_channel',
    PersonalChannelTitle = 'personal_channel_title',
    PersonalChannelDescription = 'personal_channel_description',
    PersonalChannelMembers = 'personal_channel_members',

    Name_english = 'name_english',
    Name_russian = 'name_russian',
    Name_rtl = 'name_rtl',
    Name_han = 'name_han',
    Name_regex = 'name_regex',
    Name_contains = 'name_contains',
    Username = 'username',
    Real_name = 'real_name',

    Description_english = 'description_english',
    Description_russian = 'description_russian',
    Description_rtl = 'description_rtl',
    Description_han = 'description_han',
    Description_regex = 'description_regex',
}

export type FaceControlBoolean = {
    type: FaceControlConditionTypes
    value: boolean
}

export type FaceControlPercent = {
    type: FaceControlConditionTypes
    percent: number
}

export type FaceControlRegex = {
    type: FaceControlConditionTypes
    pattern: string
    invert: boolean
}

export type PossibleConditions = FaceControlBoolean | FaceControlPercent | FaceControlRegex

export type FaceControlStructure = {
    mode: FaceControlMode
    active: boolean
    conditions: Array<Array<PossibleConditions>>
}
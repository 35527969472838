import { BlackWhiteListItem, BlackWhiteListItemType } from "piramis-base-components/src/components/BlackWhiteList/types";
import { FaceControlConditionTypes } from "@/includes/types/FaceControl.types";
import { FaceControlCondition } from "../FaceControlCondition";

export class SuperListCondition extends FaceControlCondition {
    view = () => import('./SuperListConditionView.vue')

    list: BlackWhiteListItem<{ mode: string, value: string }> = {
        type: BlackWhiteListItemType.Blacklist,
        items: []
    }

    protected constructor(type: FaceControlConditionTypes) {
        super(type)
    }

    validate() {
        return !!this.list.items.length
    }
}
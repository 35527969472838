import { FaceControlConditionTypes } from "@/includes/types/FaceControl.types";
import { FaceControlCondition } from "../FaceControlCondition";

export class ListCondition extends FaceControlCondition {
    view = () => import('./ListConditionView.vue')

    values: Array<string> = []

    protected constructor(type: FaceControlConditionTypes) {
        super(type)
    }

    validate() {
        return !!this.values.length
    }
}

import { FaceControlCondition } from "@/includes/logic/FaceControl/conditions/FaceControlCondition";
import { FaceControlConditionTypes } from "@/includes/types/FaceControl.types";

export class PersonalChannelMembersCondition extends FaceControlCondition {
    view = () => import('./PersonalChannelMembersView.vue')

    mode: "Greater" | "Less" = "Greater"

    count = 100

    constructor() {
        super(FaceControlConditionTypes.PersonalChannelMembers)
    }

    validate() {
        return this.count > 0;
    }
}
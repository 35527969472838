import { FaceControlCondition } from "@/includes/logic/FaceControl/conditions/FaceControlCondition";
import { FaceControlConditionTypes } from "@/includes/types/FaceControl.types";

export class PercentCondition extends FaceControlCondition {
    view = () => import('./PercentConditionView.vue')

    mode: "Greater" | "Less" = "Greater"

    percent = 50

    protected constructor(type: FaceControlConditionTypes) {
        super(type)
    }

    validate() {
        return this.percent > 0;
    }
}
import { FaceControlConditionTypes } from '@/includes/types/FaceControl.types'

import { FaceControlCondition } from "./conditions/FaceControlCondition";
import { AvatarCondition } from "./conditions/AvatarCondition";
import { RealNameCondition } from "./conditions/RealNameCondition";
import { UsernameCondition } from "./conditions/UsernameCondition";
import { ReferencesCondition } from "./conditions/ReferencesCondition";
import { PremiumCondition } from "./conditions/PremiumCondition";
import { BusinessCondition } from "./conditions/BusinessCondition";
import { PersonalChannelCondition } from "./conditions/PersonalChannelCondition";
import {
    PersonalChannelDescriptionCondition
} from "./conditions/PersonalChannelDescriptionCondition";
import { PersonalChannelTitleCondition } from "./conditions/PersonalChannelTitleCondition";
import { DescriptionRegexCondition } from "./conditions/DescriptionRegexCondition";
import { NameRegexCondition } from "./conditions/NameRegexCondition";
import { DescriptionHanCondition } from "./conditions/DescriptionHanCondition";
import { DescriptionRtlCondition } from "./conditions/DescriptionRtlCondition";
import { DescriptionRussianCondition } from "./conditions/DescriptionRussianCondition";
import { DescriptionEnglishCondition } from "./conditions/DescriptionEnglishCondition";
import { NameEnglishCondition } from "./conditions/NameEnglishCondition";
import { NameRussianCondition } from "./conditions/NameRussianCondition";
import { NameRtlCondition } from "./conditions/NameRtlCondition";
import { NameHanCondition } from "./conditions/NameHanCondition";
import {
    PersonalChannelMembersCondition
} from "./conditions/PersonalChannelMembersCondition/PersonalChannelMembersCondition";
import { NameContainsCondition } from './conditions/NameContainsCondition';

import { ClassConstructor } from 'class-transformer'

export default class ConditionsFabric {
    static createCondition(type: FaceControlConditionTypes): ClassConstructor<FaceControlCondition> {
        switch (type) {
            case FaceControlConditionTypes.Avatar:
                return AvatarCondition
            case FaceControlConditionTypes.References:
                return ReferencesCondition
            case FaceControlConditionTypes.Premium:
                return PremiumCondition
            case FaceControlConditionTypes.Business:
                return BusinessCondition
            case FaceControlConditionTypes.PersonalChannel:
                return PersonalChannelCondition
            case FaceControlConditionTypes.PersonalChannelMembers:
                return PersonalChannelMembersCondition
            case FaceControlConditionTypes.PersonalChannelTitle:
                return PersonalChannelTitleCondition
            case FaceControlConditionTypes.PersonalChannelDescription:
                return PersonalChannelDescriptionCondition
            case FaceControlConditionTypes.Name_english:
                return NameEnglishCondition
            case FaceControlConditionTypes.Name_russian:
                return NameRussianCondition
            case FaceControlConditionTypes.Name_rtl:
                return NameRtlCondition
            case FaceControlConditionTypes.Name_han:
                return NameHanCondition
            case FaceControlConditionTypes.Name_regex:
                return NameRegexCondition
            case FaceControlConditionTypes.Username:
                return UsernameCondition
            case FaceControlConditionTypes.Real_name:
                return RealNameCondition
            case FaceControlConditionTypes.Name_contains:
                return NameContainsCondition
            case FaceControlConditionTypes.Description_english:
                return DescriptionEnglishCondition
            case FaceControlConditionTypes.Description_russian:
                return DescriptionRussianCondition
            case FaceControlConditionTypes.Description_rtl:
                return DescriptionRtlCondition
            case FaceControlConditionTypes.Description_han:
                return DescriptionHanCondition
            case FaceControlConditionTypes.Description_regex:
                return DescriptionRegexCondition
            default:
                throw new Error(`Unknown facecontrol condition type: ${ type }`)
        }
    }
}
import { FaceControlCondition } from "@/includes/logic/FaceControl/conditions/FaceControlCondition";
import { FaceControlConditionTypes } from "@/includes/types/FaceControl.types";

export class BooleanCondition extends FaceControlCondition {
    view = () => import('./BooleanConditionView.vue')

    value = false

    protected constructor(type: FaceControlConditionTypes) {
        super(type)
    }

    validate() {
        return true
    }
}